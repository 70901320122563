import { Customer } from 'lib/models/customer'
import { PaginationMetadata } from 'lib/models/paginationMetadata'
import { downloadFile, makeApiRequest } from 'lib/utils/apiUtils/makeApiRequest'
import { formatDateTimestamp } from 'lib/utils/formatDateTimestamp'

const url = '/customers'

export const customerService = {
    getCustomers: async (queryParams?: GetCustomersParams) =>
        makeApiRequest<GetCustomersData>({ url, method: 'GET', queryParams }),
    getCustomersWithStats: async (queryParams: GetCustomersWithStatsParams) => {
        if (queryParams.download) {
            const filename = `clientes-${formatDateTimestamp(new Date())}.csv`
            return downloadFile({ url: `${url}/stats`, queryParams, filename })
        }
        return makeApiRequest<GetCustomersWithStatsData>({
            url: `${url}/stats`,
            method: 'GET',
            queryParams,
        })
    },
    getCustomer: async ({ customer_id }) =>
        makeApiRequest({ url: `${url}/${customer_id}` }),
    create: async (body) => makeApiRequest({ url, method: 'POST', body }),
    putCustomer: async (body) =>
        makeApiRequest({
            url: `${url}/${body.customer_id}`,
            method: 'PUT',
            body,
        }),
    putCustomerValidation: async (body) =>
        makeApiRequest({
            url: `${url}/${body.customer_id}/validation`,
            method: 'PUT',
            body,
        }),
    getCustomerStatement: async ({
        customer_id,
        ...queryParams
    }: GetCustomerStatementParams) =>
        makeApiRequest({
            url: `${url}/${customer_id}/statement`,
            queryParams,
        }),
    downloadCustomerStatement: async ({
        customer_id,
        customer_name,
        ...queryParams
    }: GetCustomerStatementParams & { customer_name: string }) => {
        const filename = `Estado de Cuenta - ${customer_name}.pdf`
        return downloadFile({
            url: `${url}/${customer_id}/statement`,
            queryParams: { ...queryParams, pdf: true },
            filename,
        })
    },
    getCustomerReceivablesStatement: async ({
        customer_id,
        ...queryParams
    }: GetCustomerReceivablesStatementParams) =>
        makeApiRequest({
            url: `${url}/${customer_id}/receivables-statement`,
            queryParams,
        }),
    downloadCustomerReceivablesStatement: async ({
        customer_id,
        customer_name,
        ...queryParams
    }: GetCustomerReceivablesStatementParams & { customer_name: string }) => {
        const filename = `Estado de Cuenta - ${customer_name}.pdf`
        return downloadFile({
            url: `${url}/${customer_id}/receivables-statement`,
            queryParams: { ...queryParams, download_pdf: true },
            filename,
        })
    },
    getCustomerPortalLink: async (customer_id: number) =>
        makeApiRequest<{ portal_id: string }>({
            url: `${url}/${customer_id}/portal-link`,
        }),
}

type GetCustomerStatementParams = {
    customer_id: number
    start: Date | undefined | null
    end: Date | undefined | null
    tag_ids: number[]
    start_date: string | null
    end_date: string | null
    pdf_url?: boolean
}

type GetCustomersParams = { customer_id?: number }

type GetCustomersWithStatsParams = {
    organization_id?: number | null
    search?: string
    sort_by?: string
    limit?: number
    offset?: number
    download?: boolean
}

type GetCustomersWithStatsData = {
    paginationMetadata: PaginationMetadata
    customers: Customer[]
    exchange_rate: number
}

type GetCustomersData = { customers: Customer[] }

type GetCustomerReceivablesStatementParams = {
    customer_id: number
    has_cfdi: string | null
    hide_future_invoices: string | null
    pdf_url?: boolean
}
