import { downloadFile, makeApiRequest } from 'lib/utils/apiUtils/makeApiRequest'
import { formatDateTimestamp } from 'lib/utils/formatDateTimestamp'

const url = '/cfdis'

export const cfdiService = {
    getAll: async (queryParams) => makeApiRequest({ url, queryParams }),
    postCfdi: async (body) => makeApiRequest({ url, method: 'POST', body }),
    postCfdiIngreso: async (body) =>
        makeApiRequest({ url: `${url}/invoice`, method: 'POST', body }),
    postCfdiPagoPreview: async (body) =>
        makeApiRequest({ url: `${url}/payment`, method: 'POST', body }),
    postCfdiPagoExistingPayment: async (body) =>
        makeApiRequest({ url: `${url}/existing-payment`, method: 'POST', body }),
    postCfdiEgreso: async (body) =>
        makeApiRequest({ url: `${url}/credit`, method: 'POST', body }),
    getCfdiSync: async ({ cfdi_id }) => makeApiRequest({ url: `${url}/${cfdi_id}/sync` }),
    bulkDownload: async ({ cfdi_ids }) => {
        await downloadFile({
            url: `${url}/bulk-download`,
            queryParams: { cfdi_ids },
            filename: 'cfdis.zip',
        })
    },
    getCsvDownloadCfdis: async (queryParams) => {
        const filename = `cfdis-${formatDateTimestamp(new Date())}.csv`
        await downloadFile({
            url: `${url}/csv-download`,
            queryParams,
            filename,
        })
    },
    cancelCfdi: async ({ cfdi_id, motive, substitution, type }) => {
        const typeUrl = type === 'P' ? 'payment/' : ''
        return makeApiRequest({
            url: `${url}/${typeUrl}${cfdi_id}`,
            method: 'DELETE',
            body: { motive, substitution },
        })
    },
}
