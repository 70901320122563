import { useMemo, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { Banner, Button, Tooltip, Checkbox, TextLink } from 'ds/components'

import { cfdiService } from 'lib/services/cfdiService'
import { getCfdiIds, getInvoicePaymentsMap, getMultipleCfdisError } from '../utils'
import { dateStringToDate } from 'lib/utils/dateStringUtils'
import { mutationOnSuccess } from 'lib/utils/mutationUtils'

export const CfdiPaymentModule = ({
    onChange,
    formData,
    disabled,
    invoicesWithApplicableCfdis,
}) => {
    const [xmlUrl, setXmlUrl] = useState(null)

    const { mutate, isPending } = useMutation({
        mutationFn: cfdiService.postCfdiPagoPreview,
        onMutate: () => setXmlUrl(null),
        onError: () => toast.error('Algo salió mal.'),
        onSuccess: mutationOnSuccess({
            onSuccess: ({ message, pdf_url, xml_url }) => {
                toast.success(message)
                window.open(pdf_url, '_blank')
                setXmlUrl(xml_url)
            },
        }),
    })

    const multipleCfdisError = useMemo(
        () => getMultipleCfdisError(invoicesWithApplicableCfdis),
        [invoicesWithApplicableCfdis]
    )

    if (invoicesWithApplicableCfdis.length === 0) return ''

    if (multipleCfdisError.length) {
        return (
            <Banner variant="warning">
                <p>
                    Se encontraron cobros con multiples facturas CFDI de ingreso. Para
                    poder generar comprobante de pago, cada cobro solo debe tener una.
                </p>
                {multipleCfdisError.map((err) => (
                    <p key={err}>{err}</p>
                ))}
            </Banner>
        )
    }

    const getCfdiPagoText = (invoices) => {
        if (invoices.length === 1) return `Aplica al cobro ${invoices[0].invoice_num}`
        return `Aplica a los cobros ${invoices.map((i) => i.invoice_num).join(', ')}`
    }

    return (
        <div>
            <div className="inline inline-md">
                <Checkbox
                    checked={formData.generate_cfdi_pago}
                    onChange={(v) => onChange(v)}
                >
                    Generar{' '}
                    <Tooltip trigger="comprobante de pago CFDI">
                        {getCfdiPagoText(invoicesWithApplicableCfdis)}
                    </Tooltip>
                </Checkbox>
            </div>
            <div className="inline">
                <Button
                    size="sm"
                    variant="primaryText"
                    isLoading={isPending ? 'Generando...' : undefined}
                    disabled={disabled}
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        mutate({
                            amount_paid: formData.amount_paid,
                            currency: formData.currency,
                            exchange: formData.exchange,
                            exchange_dr: formData.exchange_dr,
                            invoices: formData.invoices,
                            date_received: dateStringToDate(formData.payment_date, {
                                endOfDay: true,
                            }),
                            payment_form: formData.paymentFormObject.code,
                            invoice_payments_map: getInvoicePaymentsMap(formData),
                            customer_id: formData.customer.customer_id,

                            mode: 'preview',
                            cfdi_ids: getCfdiIds(invoicesWithApplicableCfdis),
                        })
                    }}
                >
                    → Ver Prefactura
                </Button>
            </div>
            {xmlUrl ? (
                <div className="stacked-md">
                    <TextLink href={xmlUrl}>Baja XML de Prefactura</TextLink>
                </div>
            ) : null}
        </div>
    )
}
