export const cfdiRelationships: { code: string; description: string }[] = [
    { code: '01', description: 'Nota de crédito de los documentos relacionados' },
    { code: '02', description: 'Nota de débito de los documentos relacionados' },
    {
        code: '03',
        description: 'Devolución de mercancía sobre facturas o traslados previos',
    },
    { code: '04', description: 'Sustitución de los CFDI previos' },
    { code: '05', description: 'Traslados de mercancías facturados previamente' },
    { code: '06', description: 'Factura generada por los traslados previos' },
    { code: '07', description: 'CFDI por aplicación de anticipo' },
    { code: '08', description: 'Factura generada por pagos en parcialidades' },
    { code: '09', description: 'Factura generada por pagos diferidos' },
] as const

export const cfdiRelationshipsCredit = cfdiRelationships.filter(
    (f) => f.code === '01' || f.code === '07'
)
